.section__padding{
    padding: 4rem 4rem;
}

.footer{
    background-color: black;
}

.sb__footer{
    display:flex;
    flex-direction: column;
}

.sb__footer-links{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    text-align: left;
    margin-bottom: 2rem;
    color: white;
}

.sb__footer-links_div{
    width:150px;
    margin: 1rem;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    color: white;
}

a{
    color:  white;
    text-decoration: none;
}

.socialmedia{
    display: flex;
    flex-direction: row;
}

.socialmedia img{
    width: 80%;
}

.sb__footer-links_div h4{
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 0.9rem;
    color:white;
}

.sb__footer-links_div p{
    font-size: 12px;
    line-height: 15px;
    margin: 0.5rem 0;
    color: white;

}

.sb__footer-below{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0.2rem;
}

.sb__footer-below-links{
    display: flex;
    flex-direction: row;

}

.sb__footer-below-links p{
    font-size: 11px;
    line-height: 15px;
    margin-left: 2rem;
    color:  white;
    font-weight: 600;
}

hr{
    color: black;
    height:5px;
    width: 100%;
}

.sb__footer-copyright p{
    font-size: 11px;
    line-height: 15px;
    color: rgb(245, 235, 235);
    font-weight: 600;
}

@media screen and (max-width: 850px){

    .sb__footer-heading h1{
        font-size: 44px;
        line-height: 50px;
    }
}

@media screen and (max-width: 550px){

    .sb__footer-heading h1{
        font-size: 35px;
        line-height: 42px;
    }

    .sb__footer-links div{
        margin: 1rem 0;
    }

    .sb__footer-btn p{
        font-size: 14px;
        line-height: 20px;
    }

    .sb__footer-below{
        flex-direction: column;
        justify-content: left;
    }

    .sb__footer-below-links{
        flex-direction: column;
    }

    .sb__footer-below-links p{
        margin-left: 0rem;
        margin-top: 1rem;
    }
}

@media screen and (max-width: 400px){

    .sb__footer-heading h1{
        font-size: 27px;
        line-height: 38px;
    }
    
}