.show-t1{
    position: relative; width: 40%; height: 0; padding-top: 56.2225%;
    padding-bottom: 48px; box-shadow: 0 2px 8px 0 rgba(63,69,81,0.16); margin-top: 1.6em; margin-bottom: 0.9em; overflow: hidden;
    border-radius: 8px; will-change: transform;

}

.show_school{
    background-color: rgb(237, 240, 243);
}

.show-t1-f{
    position: absolute; width: 100%; height: 100%; top: 0; left: 0; border: none; padding: 0;margin: 0;
}

.show_canva{
    position: relative; width: 100%; height: 0; padding-top: 56.2225%;
    padding-bottom: 48px; box-shadow: 0 2px 8px 0 rgba(63,69,81,0.16); margin-top: 1.6em; margin-bottom: 0.9em; overflow: hidden;
    border-radius: 8px; will-change: transform;
}
.show_school{
    background-color: rgb(237, 240, 243);
}

.show-c-frame{
    position: absolute; width: 100%; height: 100%; top: 0; left: 0; border: none; padding: 0;margin: 0;
}
