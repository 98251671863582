.columnCareer {
    float: left;
    width: 50%;
    padding: 0 10px;
    background-color: black;
  }

@media screen and (max-width: 780px) {

    .columnCareer{
        float: left;
        margin-top: 15px;
        width: 100%;
        background-color: black;
    }

  }
