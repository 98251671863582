.top_strip {
  background-color: transparent;
  height: auto;
  width:auto;
}

.strip_hello {

  margin-left: 25%;
  margin-top: 0.5%;
  display: inline-block;
}

.strip_events {
  margin-left: 35%;
  display: inline-block;
}

.strip_giftcard {
  margin-left: 5%;
  display: inline-block;
}

.strip_help {
  margin-left: 5%;
  display: inline-block;
}

/*.strip_hello-mobile{
  display: none;
}*/



/*@media only screen and (max-width: 780px){
  .strip_hello-mobile{
    display:"absolute";
  }
}*/

/*
background-image: url("https://firebasestorage.googleapis.com/v0/b/admin-5d27b.appspot.com/o/assets%2Fontop%20(7).png?alt=media&token=9c4f3824-b70a-478d-bfbe-c45d85d4e669");
  
*/