.post {
  background-color: white;
  max-width: 600px;
  width: 90vw;
  padding: 16px;
  margin-bottom: 8px;
}

.post__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.post__headerLeft {
  display: flex;
  align-items: center;
}

.post__profilePic {
  height: 35px;
  border-radius: 8px;
}

.post__delete {
  border: none;
  background-color: white;
  cursor: pointer;
}

.post__delete:focus {
  outline: none;
}

.post__photoUrl {
  width: 100%;
  object-fit: cover;
  margin: 16px 0px;
}
