
.column {
    float: left;
    width: 50%;
    padding: 0 10px;
  }

@media screen and (max-width: 780px) {

    .column{
        float: left;
        width: 100%;
    }

  }