
.show-login-page{

    background-image: "logo.png";
  background-size: "cover";
  width: 100%;
  height: auto;
  background-repeat:no-repeat; 

}

.login-container{

    background-image: url("/public/login-bg.png");
    background-repeat: no-repeat;
    width: 100%;
}

.show-footer{
    margin-top:10%;
}

@media screen and (max-width: 780px) {
    
    .login-container{

        background-image: url("/public/login-bg-mobile.png");
        background-size: contain;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
    }

    .show-stuff{
        display: none;
    }

    .show-footer{
        margin-top: 59%;
    }
}



