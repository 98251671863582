#footer {
    position: fixed;
    bottom: 0;
    width: 100%;
 }

 .setCarsoel{
    margin-top: auto;
 }
 
 .show-social{
   height: 100%;
   width: 100%;
 }
 .set-together{display:flex; flex-direction:row;}


 .showNews{
   width:"750px";
 }

 .showVideo{
   
   margin-left: 35px;
   height:"auto"
 }

 @media screen and (max-width: 780px) {
   .showImg{
      margin-top: 85px;
   }

   .set-together{display:flex; flex-direction:column;}

   .show-social{
      height: 100%;
      width: 100%;
   }

   .showNews{
      margin-top: 5px;
      width: 90%;
   }

   .showVideo{
      margin-top: 35px;
      width:90%;
      position: relative;
   }
 }