.show{
    position: relative;
   
}

.show-mobile{
    display: flex;
    margin-top:"100px";
}

@media screen and (max-width: 780px) {
    .show{
       position: relative;
       top: 5%;
    }
}